<template>
  <v-container fluid>
    <v-row>
      <v-col class="mt-0 pt-0 pb-0" cols="12">
        <v-card>
          <v-card-actions>
            <v-icon @click="close">mdi-arrow-left-circle</v-icon>

            <v-spacer></v-spacer>
            <v-select
              v-model="deleted"
              :items="[
                { text: 'Active budget items', value: false },
                { text: 'Deleted budget items', value: true },
              ]"
              @change="toggleDeleted"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn @click="addItem">Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-data-table
              id="list-table"
              ref="datatable"
              :headers="headers"
              :items="budgetItems"
              :loading="loading"
              item-key="index"
              class="mr-2"
              v-if="!deleted"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td>{{ props.item.title }}</td>
                  <td>{{ props.item.description }}</td>
                  <td class="text-right">{{ util.toFixed(props.item.budget, 2) }}</td>
                  <td class="text-right">
                    {{
                      util.isEmpty(props.item.spent)
                        ? "0"
                        : util.toFixed(props.item.spent, 2)
                    }}
                  </td>
                  <td class="text-right">
                    <span v-if="!util.isEmpty(props.item.spent)">
                      {{
                        util.isEmpty(props.item.spent.Hours) ||
                        props.item.spent.Hours == 0
                          ? ""
                          : util.toFixed(props.item.spent.Hours, 2) + " Hours"
                      }}

                      {{
                        util.isEmpty(props.item.spent.Days) || props.item.spent.Days == 0
                          ? ""
                          : util.toFixed(props.item.spent.Days, 2) + " Days"
                      }}

                      {{
                        util.isEmpty(props.item.spent.Weeks) ||
                        props.item.spent.Weeks == 0
                          ? ""
                          : util.toFixed(props.item.spent.Weeks, 2) + " Weeks"
                      }}

                      {{
                        util.isEmpty(props.item.spent.Years) ||
                        props.item.spent.Years == 0
                          ? ""
                          : util.toFixed(props.item.spent.Years, 2) + " Years"
                      }}
                    </span>
                  </td>

                  <td class="justify-center layout px-0">
                    <v-icon small class="mr-2" @click="editItem(props.item)"
                      >mdi-pencil</v-icon
                    >

                    <v-icon slot="activator" small @click="deleteItem(props.item)"
                      >mdi-delete</v-icon
                    >
                  </td>
                </tr>
              </template>
            </v-data-table>

            <v-data-table
              id="list-table"
              ref="datatable"
              :headers="deletedHeaders"
              :items="deletedBudgetItems"
              :loading="loading"
              item-key="index"
              class="mr-2"
              v-if="deleted"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td>{{ props.item.title }}</td>
                  <td>{{ props.item.description }}</td>
                  <td class="text-right">{{ util.toFixed(props.item.budget, 2) }}</td>
                  <!-- <td class="text-right">{{util.isEmpty(props.item.spent)?"0":
                    util.isEmpty(props.item.spent.dollars)?"0":
                     util.toFixed(props.item.spent.dollars,2)}}</td>-->
                   <td class="text-right">
                    {{
                      util.isEmpty(props.item.spent)
                        ? "0"
                        : util.toFixed(props.item.spent, 2)
                    }}
                  <td class="text-right">
                    <span v-if="!util.isEmpty(props.item.spent)">
                      {{
                        util.isEmpty(props.item.spent.Hours) ||
                        props.item.spent.Hours == 0
                          ? ""
                          : util.toFixed(props.item.spent.Hours, 2) + " Hours"
                      }}

                      {{
                        util.isEmpty(props.item.spent.Days) || props.item.spent.Days == 0
                          ? ""
                          : util.toFixed(props.item.spent.Days, 2) + " Days"
                      }}

                      {{
                        util.isEmpty(props.item.spent.Weeks) ||
                        props.item.spent.Weeks == 0
                          ? ""
                          : util.toFixed(props.item.spent.Weeks, 2) + " Weeks"
                      }}

                      {{
                        util.isEmpty(props.item.spent.Years) ||
                        props.item.spent.Years == 0
                          ? ""
                          : util.toFixed(props.item.spent.Years, 2) + " Years"
                      }}
                    </span>
                  </td>

                  <td>
                    {{
                      props.item.deletedOn == undefined
                        ? ""
                        : moment(props.item.deletedOn.toDate()).format("YYYY-MM-DD hh:mm")
                    }}
                  </td>

                  <td>
                    {{
                      props.item.deletedBy == undefined
                        ? ""
                        : props.item.deletedBy.displayName
                    }}
                  </td>

                  <td>
                    <v-icon small @click="unDeleteItem(props.item)" v-if="isAdmin"
                      >mdi-undo-variant</v-icon
                    >
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as budgetItemService from "../../../services/budgetItemService";
//import * as notesService from "../../../services/progressNotesService";
//import * as goalService from "../../../services/goalService.js";
import * as reportService1 from "../../../services/reportService1.js";
import moment from "moment";
Vue.use(VeeValidate);

export default {
  name: "budgetItems",

  data: () => ({
    vm: {},
    util: null,
    isAdmin: false,
    deleted: false,
    valid: true,
    budgetItems: [],
    deletedBudgetItems: [],
    totalItems: 0,
    loading: false,
    queryFilters: [],
    goals: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 },
    ],
    headers: [
      { text: "Title", value: "title" },
      { text: "Description", value: "description" },
      { text: "Budget", value: "budget", align: "end" },
      { text: "Spent ($)", value: "spent", align: "end" },
      { text: "Spent (duration)", value: "spent", align: "end" },
      { text: "", value: "actions" },
    ],
    deletedHeaders: [
      { text: "Title", value: "title" },
      { text: "Description", value: "description" },
      { text: "Budget", value: "budget", align: "end" },
      { text: "Spent ($)", value: "spent", align: "end" },
      { text: "Spent (duration)", value: "spent", align: "end" },
      { text: "Deleted On", value: "deletedOn" },
      { text: "Deleted By", value: "deletedBy" },
      { text: "", value: "actions" },
    ],
  }),

  mounted() {
    this.util = this.$utils;
    if (
      this.user.roles.indexOf("System Administrator") > -1 ||
      this.user.roles.indexOf("Organisation Administrator") > -1
    ) {
      this.isAdmin = true;
    }
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },

    user() {
      return this.$store.getters.user;
    },
  },
  methods: 
  {
   init() 
    {
      console.log(
        `caseid: ${this.currentOrganisation.id}, participantid: ${this.currentCase.id}`
      );
      this.vm = {};
      budgetItemService
        .list(this.currentOrganisation.id, this.currentCase.id)
        .then((result) => {
          let budgetItems = [];
          let reportObj={};
          let reportData=[];
          result.docs.map((doc) => {
            const item = doc.data();
            item.id = doc.id;
            budgetItems.push(item);
          });

         console.log(budgetItems);
         this.budgetItems=budgetItems;

          if (this.budgetItems.length != 0) {
                for (let p = 0; p < this.budgetItems.length; p++) {
                  console.log(this.budgetItems[p].length);
                  reportObj["budget"] = this.budgetItems[p].budget;
                  reportObj["budgetItem"] = this.budgetItems[p].title;
                  reportObj["budgetItemId"] = this.budgetItems[p].id;
                  reportObj["budgetItemLongName"] =
                    this.currentCase.participantDetails.firstname +
                    " " +
                    this.currentCase.participantDetails.lastname +
                    " " +
                    "-" +
                    this.budgetItems[p].title;
                  reportObj["firstname"] = this.currentCase.participantDetails.firstname;
                  reportObj["lastname"] = this.currentCase.participantDetails.lastname;
                  reportObj["organisation"] = this.currentOrganisation.name;
                  reportObj["participantId"] = this.currentCase.id;
                  if (this.budgetItems[p].spent !== undefined) {
                    reportObj["totalSpent"] = this.budgetItems[p].spent;
                  } else {
                    reportObj["totalSpent"] = 0;
                  }

                  reportData.push(reportObj);
                  reportObj = {};
                }
                console.log(reportData);
              }
            //  this.deleteReport(reportData);
             // this.addReport(reportData);
                     
        });
      
      
     },
    moment: function (date) {
      return date != undefined ? moment(date) : moment();
    },
    close() {
      this.$router.push("/participant-management/current-participant/invoicing-billing");
      setTimeout(() => {
        this.vm = {};
      }, 300);
    },
    editItem(item) {
      // this.editedIndex = this.budgetItems.indexOf(item);
      // this.vm = Object.assign({}, item);
      // this.dialog = true;

      this.$store.commit("setCurrentBudgetItem", JSON.parse(JSON.stringify(item)));
      this.$router.push(
        "/participant-management/current-participant/budget-items/details"
      );
    },
    addItem() {
      this.$store.commit("setCurrentBudgetItem", {});
      this.$router.push(
        "/participant-management/current-participant/budget-items/details"
      );
    },
    itemAdded(data) {
      if (this.editedIndex > -1) {
        Object.assign(this.budgetItems[this.editedIndex], data);
      } else {
        this.budgetItems.push(data);
      }
      this.itemClosed();
      console.log(data);
    },
    itemClosed() {
      this.dialog = false;
      setTimeout(() => {
        this.vm = {};
        this.editedIndex = -1;
        this.$forceUpdate();
      }, 300);
    },

    addGoal() {
      this.$router.push("/budget-item-details");
    },

    addBudgetItem() {
      this.dialog = true;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    toggleDeleted() {
      //this.deleted = !this.deleted;
      if (this.deleted) {
        this.getDeleted();
      }
    },
    getDeleted() {
      budgetItemService
        .listRemoved(this.currentOrganisation.id, this.currentCase.id)
        .then((result) => {
          let budgetItems = [];
          result.docs.map((doc) => {
            const goal = doc.data();
            goal.id = doc.id;
            budgetItems.push(goal);
          });

          this.deletedBudgetItems = budgetItems.sort((ax, bx) => {
            if (ax.modifiedOn == undefined) {
              return 1;
            }
            if (bx.modifiedOn == undefined) {
              return -1;
            }
            let a = ax.modifiedOn.toDate();
            let b = bx.modifiedOn.toDate();
            return a > b ? -1 : a < b ? 1 : 0;
          });
        });
    },
    deleteItem(item) {
      const me = this;

      const index = me.budgetItems.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        budgetItemService
          .remove(me.currentOrganisation.id, me.currentCase.id, item.id, me.user)
          .then((result) => {
            console.log(result);
            me.budgetItems.splice(index, 1);
          });
      }
    },
    unDeleteItem(item) {
      const me = this;
      const index = me.budgetItems.indexOf(item);

      budgetItemService
        .unRemove(me.currentOrganisation.id, me.currentCase.id, item.id)
        .then((result) => {
          console.log(result);
          me.budgetItems.push(item);
          me.deletedBudgetItems.splice(index, 1);
        });
    },

    deleteReport(reportData) {
      reportService1
        .getBudgetItemsTotalsReport1(this.currentOrganisation.id)
        .then((doc) => {
          const rows = doc.data().rows;

          for (let i = 0; i < rows.length; i++) {
            for (let j = 0; j < reportData.length; j++) {
              if (rows[i].budgetItemId == reportData[j].budgetItemId) {
                if (
                  rows[i].budget != reportData[j].budget &&
                  rows[i].totalSpent == reportData[j].totalSpent
                ) {
                  console.log(rows[i]);
                  reportService1
                    .deleteReport(this.currentOrganisation.id, rows[i])
                    .then((result) => {
                      console.log("report deleted", result);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
                if (
                  rows[i].budget == reportData[j].budget &&
                  rows[i].totalSpent != reportData[j].totalSpent
                ) {
                  //console.log(rows[i]);
                  reportService1
                    .deleteReport(this.currentOrganisation.id, rows[i])
                    .then((result) => {
                      console.log("report deleted", result);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
                if (
                  rows[i].budget != reportData[j].budget &&
                  rows[i].totalSpent != reportData[j].totalSpent
                ) {
                  console.log(rows[i]);
                  reportService1
                    .deleteReport(this.currentOrganisation.id, rows[i])
                    .then((result) => {
                      console.log("report deleted", result);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }
            }
          }
        });
    },
    addReport(reportData) {
      for (let x = 0; x < reportData.length; x++) {
        reportService1
          .addReport(this.currentOrganisation.id, reportData[x])
          .then((result) => {
            console.log("report added", result);
          })
          .catch((err) => {
            console.log(reportData[x]);
            console.log(err);
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
